import Vue from "vue";
import VueRouter from "vue-router";
import mainLayout from "@/components/mainLayout"
import index from "./modules/index"
import customer from "./modules/customer"
import works from "./modules/works"
import platform from "./modules/platform"
import notice from "./modules/notice"
import agent from "./modules/agent"
import operation from "./modules/operation"
import edition from "./modules/edition"
Vue.use(VueRouter);

const router = new VueRouter({
    routes:[
        {
            name: '登录',
            path: '/',
            component: ()=>import("@/pages/login/login.vue")
        },
        {
            path:'/admin',
            meta: {title: '餐饮系统'},
            component: mainLayout,
            children: [
                ...index,
                ...edition,
                customer,
                ...works,
                ...platform,
                ...notice,
                ...agent,
                ...operation
            ]
        }

    ]
})

router.beforeEach((to, from, next) => {
    window.document.title = "餐饮sass平台";
    next();
})
export default router;
